import React, { createContext, useState, useContext, useEffect } from 'react';

const ThemeContext = createContext();

const defaultColors = {
  primary: '#007AFF',
  primaryDark: '#0056b3',
  secondary: '#34C759',
  secondaryDark: '#218838',
  accent: '#6366f1',
  accentDark: '#4f46e5',
  background: '#ffffff',
  darkBackground: '#1a1a1a',
  cardBackground: '#ffffff',
  darkCardBackground: '#2d2d2d',
  text: '#2c3e50',
  darkText: '#ffffff',
  border: '#dee2e6',
  darkBorder: '#444444',
  success: '#28a745',
  error: '#dc3545',
  warning: '#ffc107',
  info: '#17a2b8'
};

export function ThemeProvider({ children }) {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode');
    return saved ? JSON.parse(saved) : false;
  });

  const [colors, setColors] = useState(() => {
    const saved = localStorage.getItem('themeColors');
    return saved ? JSON.parse(saved) : defaultColors;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
    localStorage.setItem('themeColors', JSON.stringify(colors));
    
    // Update both body class and data attribute
    document.body.classList.toggle('dark-mode', isDarkMode);
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
    
    // Apply custom colors to CSS variables
    const root = document.documentElement;
    Object.entries(colors).forEach(([key, value]) => {
      root.style.setProperty(`--color-${key}`, value);
    });
  }, [isDarkMode, colors]);

  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);
  const updateColors = (newColors) => setColors({ ...colors, ...newColors });

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode, colors, updateColors }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  return useContext(ThemeContext);
} 
