import React, { useState } from 'react';
import { useTheme } from '../context/ThemeContext';
import './ColorPicker.css';

function ColorPicker() {
  const { colors, updateColors } = useTheme();
  const [tempColors, setTempColors] = useState(colors);
  const [hasChanges, setHasChanges] = useState(false);

  const handleColorChange = (name, value) => {
    setTempColors(prev => ({ ...prev, [name]: value }));
    setHasChanges(true);
  };

  const handleSave = () => {
    updateColors(tempColors);
    setHasChanges(false);
  };

  const handleReset = (name, defaultColor) => {
    setTempColors(prev => ({ ...prev, [name]: defaultColor }));
    setHasChanges(true);
  };

  const colorOptions = [
    { 
      title: 'Primary Colors',
      colors: [
        { name: 'primary', label: 'Primary', default: '#007AFF' },
        { name: 'primaryDark', label: 'Primary Dark', default: '#0056b3' }
      ]
    },
    {
      title: 'Secondary Colors',
      colors: [
        { name: 'secondary', label: 'Secondary', default: '#34C759' },
        { name: 'secondaryDark', label: 'Secondary Dark', default: '#218838' }
      ]
    },
    {
      title: 'Accent Colors',
      colors: [
        { name: 'accent', label: 'Accent', default: '#6366f1' },
        { name: 'accentDark', label: 'Accent Dark', default: '#4f46e5' }
      ]
    },
    {
      title: 'Background Colors',
      colors: [
        { name: 'background', label: 'Background', default: '#ffffff' },
        { name: 'darkBackground', label: 'Dark Background', default: '#1a1a1a' },
        { name: 'cardBackground', label: 'Card Background', default: '#ffffff' },
        { name: 'darkCardBackground', label: 'Dark Card Background', default: '#2d2d2d' }
      ]
    },
    {
      title: 'Text Colors',
      colors: [
        { name: 'text', label: 'Text', default: '#2c3e50' },
        { name: 'darkText', label: 'Dark Text', default: '#ffffff' }
      ]
    },
    {
      title: 'Border Colors',
      colors: [
        { name: 'border', label: 'Border', default: '#dee2e6' },
        { name: 'darkBorder', label: 'Dark Border', default: '#444444' }
      ]
    },
    {
      title: 'Status Colors',
      colors: [
        { name: 'success', label: 'Success', default: '#28a745' },
        { name: 'error', label: 'Error', default: '#dc3545' },
        { name: 'warning', label: 'Warning', default: '#ffc107' },
        { name: 'info', label: 'Info', default: '#17a2b8' }
      ]
    }
  ];

  return (
    <div className="color-picker">
      <div className="color-picker-header">
        <h3>Theme Colors</h3>
        {hasChanges && (
          <button 
            onClick={handleSave}
            className="save-button"
          >
            Save Changes
          </button>
        )}
      </div>
      {colorOptions.map((group) => (
        <div key={group.title} className="color-group">
          <h4>{group.title}</h4>
          <div className="color-options">
            {group.colors.map(({ name, label, default: defaultColor }) => (
              <div key={name} className="color-option">
                <label>{label}</label>
                <div className="color-input-wrapper">
                  <input
                    type="color"
                    value={tempColors[name]}
                    onChange={(e) => handleColorChange(name, e.target.value)}
                  />
                  <button
                    onClick={() => handleReset(name, defaultColor)}
                    className="reset-button"
                  >
                    Reset
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ColorPicker; 
