import React, { useState, useEffect, useCallback } from 'react';
import './IPManagement.css';

function IPManagement({ credentials }) {
  const [ipRules, setIpRules] = useState([]);
  const [newRule, setNewRule] = useState({ ip: '', action: 'block', note: '' });
  const [isEnabled, setIsEnabled] = useState(false);
  const [error, setError] = useState(null);

  const fetchIPRules = useCallback(async () => {
    try {
      console.log('Fetching IP rules...');
      const response = await fetch('/api/admin/ip-rules', {
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Received IP rules:', data);
        setIpRules(data.rules);
      }
    } catch (error) {
      console.error('Failed to fetch IP rules:', error);
      setError('Failed to fetch IP rules');
    }
  }, [credentials]);

  const fetchIPFilterStatus = useCallback(async () => {
    try {
      const response = await fetch('/api/admin/ip-filter/status', {
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setIsEnabled(data.enabled);
      }
    } catch (error) {
      console.error('Failed to fetch IP filter status:', error);
    }
  }, [credentials]);

  useEffect(() => {
    fetchIPRules();
    fetchIPFilterStatus();
  }, [fetchIPRules, fetchIPFilterStatus]);

  const handleToggleFilter = async () => {
    try {
      const response = await fetch('/api/admin/ip-filter/toggle', {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ enabled: !isEnabled })
      });
      if (response.ok) {
        setIsEnabled(!isEnabled);
      }
    } catch (error) {
      console.error('Failed to toggle IP filter:', error);
      setError('Failed to toggle IP filter');
    }
  };

  const handleAddRule = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/admin/ip-rules', {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newRule)
      });
      if (response.ok) {
        setNewRule({ ip: '', action: 'block', note: '' });
        fetchIPRules();
      }
    } catch (error) {
      console.error('Failed to add IP rule:', error);
      setError('Failed to add IP rule');
    }
  };

  const handleDeleteRule = async (ip) => {
    try {
      const response = await fetch(`/api/admin/ip-rules/${encodeURIComponent(ip)}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        fetchIPRules();
      }
    } catch (error) {
      console.error('Failed to delete IP rule:', error);
      setError('Failed to delete IP rule');
    }
  };

  return (
    <div className="ip-management">
      <div className="ip-management-header">
        <h2>IP Management</h2>
        <div className="filter-toggle">
          <label className="switch">
            <input
              type="checkbox"
              checked={isEnabled}
              onChange={handleToggleFilter}
            />
            <span className="slider"></span>
          </label>
          <span>{isEnabled ? 'IP Filtering Enabled' : 'IP Filtering Disabled'}</span>
        </div>
      </div>

      <form onSubmit={handleAddRule} className="add-rule-form">
        <input
          type="text"
          placeholder="IP Address (e.g., 192.168.1.1 or 192.168.1.0/24)"
          value={newRule.ip}
          onChange={(e) => setNewRule({ ...newRule, ip: e.target.value })}
          required
        />
        <select
          value={newRule.action}
          onChange={(e) => setNewRule({ ...newRule, action: e.target.value })}
        >
          <option value="block">Block</option>
          <option value="allow">Allow</option>
        </select>
        <input
          type="text"
          placeholder="Note (optional)"
          value={newRule.note}
          onChange={(e) => setNewRule({ ...newRule, note: e.target.value })}
        />
        <button type="submit">Add Rule</button>
      </form>

      {error && <div className="error-message">{error}</div>}

      <div className="ip-rules-list">
        <table>
          <thead>
            <tr>
              <th>IP Address</th>
              <th>Action</th>
              <th>Note</th>
              <th>Added</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {ipRules.map((rule) => (
              <tr key={rule.ip}>
                <td>{rule.ip}</td>
                <td>
                  <span className={`status-badge ${rule.action}`}>
                    {rule.action}
                  </span>
                </td>
                <td>{rule.note}</td>
                <td>{new Date(rule.added).toLocaleString()}</td>
                <td>
                  <button
                    onClick={() => handleDeleteRule(rule.ip)}
                    className="delete-rule"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default IPManagement; 
