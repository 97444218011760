import React, { useState, useEffect } from 'react';
import { useTheme } from '../context/ThemeContext';
import logo from '../assets/logos/logo-192.png';
import '../styles/MainApp.css';

const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) || 
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
};

function MainApp() {
  const { isDarkMode } = useTheme();
  const [scannedFiles, setScannedFiles] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showIOSPrompt, setShowIOSPrompt] = useState(false);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Check if it's iOS and the app isn't installed
    if (isIOS() && !window.navigator.standalone) {
      setShowIOSPrompt(true);
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });

    // Add to home screen prompt for Safari
    window.addEventListener('appinstalled', () => {
      setDeferredPrompt(null);
      setShowIOSPrompt(false);
    });

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('appinstalled', () => {});
    };
  }, []);

  // Handle camera capture
  const handleCapture = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const type = file.type;
      if (type.startsWith('image/')) {
        const reader = new FileReader();
        const imagePromise = new Promise((resolve) => {
          reader.onloadend = () => resolve(reader.result);
        });
        reader.readAsDataURL(file);
        const imageData = await imagePromise;
        setScannedFiles(prev => [...prev, imageData]);
        setFileTypes(prev => [...prev, type]);
      }
      // Reset input to allow taking another photo
      event.target.value = '';
    }
  };

  // Remove a file from the list
  const handleRemoveFile = (index) => {
    setScannedFiles(files => files.filter((_, i) => i !== index));
    setFileTypes(types => types.filter((_, i) => i !== index));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (scannedFiles.length === 0) {
      alert('Please capture or upload at least one file');
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      
      for (let i = 0; i < scannedFiles.length; i++) {
        if (fileTypes[i].startsWith('image/')) {
          // Convert base64 image to blob
          const response = await fetch(scannedFiles[i]);
          const blob = await response.blob();
          formData.append('timesheets', blob, `timesheet-${i+1}.jpg`);
        } else {
          // Append PDF directly
          formData.append('timesheets', scannedFiles[i], `timesheet-${i+1}.pdf`);
        }
      }

      // Send to server
      const submitResponse = await fetch('/api/submit-timesheet', {
        method: 'POST',
        body: formData
      });

      if (!submitResponse.ok) {
        throw new Error('Failed to submit timesheets');
      }

      alert('Timesheets submitted successfully!');
      setScannedFiles([]);
      setFileTypes([]);
    } catch (error) {
      alert('Error submitting timesheets: ' + error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Add install button handler
  const handleInstall = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        setDeferredPrompt(null);
      }
    }
  };

  return (
    <div className={`container ${isDarkMode ? 'dark-mode' : ''}`}>
      {!isOnline && (
        <div className="offline-banner">
          You are offline. Changes will sync when you're back online.
        </div>
      )}
      <div className="header">
        <img src={logo} alt="Timesheet Scanner Logo" className="app-logo" />
        <h1 className="title">Timesheet Scanner</h1>
      </div>
      {(deferredPrompt || showIOSPrompt) && (
        <button 
          onClick={handleInstall} 
          className="install-button"
        >
          <svg 
            width="20" 
            height="20" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
            <polyline points="7 10 12 15 17 10" />
            <line x1="12" y1="15" x2="12" y2="3" />
          </svg>
          {showIOSPrompt ? 'Add to Home Screen' : 'Install App'}
        </button>
      )}
      
      {showIOSPrompt && (
        <div className="ios-install-prompt">
          <p>To install this app on your iPhone:</p>
          <ol>
            <li>Tap the Share button <span className="ios-share-icon">⎙</span></li>
            <li>Scroll down and tap "Add to Home Screen"</li>
            <li>Tap "Add" to confirm</li>
          </ol>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="timesheet-form">
        <div className="capture-section">
          <input
            type="file"
            accept="image/*"
            capture="environment"
            onChange={handleCapture}
            id="capture"
            className="file-input"
            disabled={loading}
          />
          <label htmlFor="capture" className="capture-button">
            {scannedFiles.length > 0 ? 'Take Another Photo' : 'Take Photo'}
          </label>
        </div>

        {scannedFiles.length > 0 && (
          <div className="files-list">
            {scannedFiles.map((file, index) => (
              <div key={index} className="file-item">
                <div className="preview-container">
                  <img src={file} alt={`Timesheet ${index + 1}`} className="preview" />
                  <button 
                    type="button" 
                    className="remove-button"
                    onClick={() => handleRemoveFile(index)}
                  >
                    ×
                  </button>
                  <div className="photo-number">Photo {index + 1}</div>
                </div>
              </div>
            ))}
            
            <button 
              type="submit" 
              className="submit-button" 
              disabled={loading || scannedFiles.length === 0}
            >
              {loading ? 'Processing...' : `Send ${scannedFiles.length} Photo${scannedFiles.length > 1 ? 's' : ''}`}
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default MainApp; 
