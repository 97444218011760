import React, { useState, useEffect, useCallback } from 'react';
import './Admin.css';
import { useTheme } from '../context/ThemeContext';
import ColorPicker from './ColorPicker';
import IPManagement from './IPManagement';

function Admin() {
  const [submissions, setSubmissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const [stats, setStats] = useState({
    totalSubmissions: 0,
    todaySubmissions: 0,
    pendingReview: 0,
    lastSubmission: null
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFilter, setDateFilter] = useState('all'); // all, today, week, month
  const [selectedSubmissions, setSelectedSubmissions] = useState([]);
  const { isDarkMode, toggleDarkMode } = useTheme();
  const [deletingId, setDeletingId] = useState(null);

  // Add credentials variable
  const credentials = btoa(`${loginData.username}:${loginData.password}`);

  // Add selection handlers
  const handleSelectSubmission = (submissionId) => {
    setSelectedSubmissions(prev => {
      if (prev.includes(submissionId)) {
        return prev.filter(id => id !== submissionId);
      } else {
        return [...prev, submissionId];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedSubmissions(submissions.map(s => s.id));
    } else {
      setSelectedSubmissions([]);
    }
  };

  // Add bulk action handlers
  const handleBulkDownload = async () => {
    try {
      const response = await fetch('/api/admin/bulk-download', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${credentials}`
        },
        body: JSON.stringify({ ids: selectedSubmissions })
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'timesheets.zip';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setSelectedSubmissions([]);
      }
    } catch (error) {
      console.error('Bulk download error:', error);
      setError('Failed to download files');
    }
  };

  const handleBulkArchive = async () => {
    if (!window.confirm('Are you sure you want to archive these submissions?')) {
      return;
    }

    try {
      const response = await fetch('/api/admin/bulk-archive', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${credentials}`
        },
        body: JSON.stringify({ ids: selectedSubmissions })
      });

      if (response.ok) {
        await fetchSubmissions();
        setSelectedSubmissions([]);
      }
    } catch (error) {
      console.error('Bulk archive error:', error);
      setError('Failed to archive files');
    }
  };

  // Handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/admin/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setIsAuthenticated(true);
        fetchSubmissions();
      } else {
        setError('Invalid credentials');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('Login failed');
    }
  };

  // Fetch submissions
  const fetchSubmissions = async () => {
    try {
      // Create Basic Auth header
      const credentials = btoa(`${loginData.username}:${loginData.password}`);
      
      console.log('Fetching submissions with auth...');
      const response = await fetch('/api/admin/submissions', {
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json'
        }
      });
      
      const data = await response.json();
      console.log('Submissions response:', data);
      
      if (response.ok) {
        setSubmissions(data);
        setError(null);
        fetchStats();
      } else {
        console.error('Fetch error:', data);
        setError(data.error || 'Failed to fetch submissions');
      }
    } catch (err) {
      console.error('Fetch error:', err);
      setError('Error loading submissions');
    } finally {
      setIsLoading(false);
    }
  };

  // Add delete handler
  const handleDelete = async (submissionId) => {
    if (!window.confirm('Are you sure you want to delete this submission?')) {
      return;
    }

    setDeletingId(submissionId);
    try {
      const response = await fetch(`/api/admin/submissions/${submissionId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        fetchSubmissions();
      } else {
        setError('Failed to delete submission');
      }
    } catch (error) {
      console.error('Delete error:', error);
      setError('Error deleting submission');
    } finally {
      setDeletingId(null);
    }
  };

  // Update the view button code to use a simple filename extraction function
  const getFileName = (filePath) => {
    return filePath.split('/').pop();
  };

  // Add this helper function
  const formatLocation = (geoLocation) => {
    if (!geoLocation) return 'Unknown';
    const parts = [];
    if (geoLocation.city) parts.push(geoLocation.city);
    if (geoLocation.country) parts.push(geoLocation.country);
    return parts.length ? parts.join(', ') : 'Unknown';
  };

  // Wrap fetchStats in useCallback
  const fetchStats = useCallback(async () => {
    try {
      const response = await fetch('/api/admin/stats', {
        headers: {
          'Authorization': `Basic ${credentials}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setStats(data);
      }
    } catch (error) {
      console.error('Stats fetch error:', error);
    }
  }, [credentials]); // Add credentials as dependency

  useEffect(() => {
    if (isAuthenticated) {
      fetchStats();
    }
  }, [isAuthenticated, fetchStats]);

  if (!isAuthenticated) {
    return (
      <div className="admin-login">
        <h2>Admin Login</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <input
              type="text"
              placeholder="Username"
              value={loginData.username}
              onChange={(e) => setLoginData({ ...loginData, username: e.target.value })}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="Password"
              value={loginData.password}
              onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    );
  }

  return (
    <div className={`admin-dashboard ${isDarkMode ? 'dark-mode' : ''}`}>
      <header className="admin-header">
        <h1>Admin Dashboard</h1>
        <div className="header-actions">
          <button 
            onClick={toggleDarkMode} 
            className="theme-toggle"
            aria-label="Toggle dark mode"
          >
            {isDarkMode ? '☀️' : '🌙'}
          </button>
          <button 
            onClick={() => setIsAuthenticated(false)} 
            className="logout-button"
          >
            Logout
          </button>
        </div>
      </header>

      <div className="dashboard-content">
        <ColorPicker />
        <IPManagement credentials={credentials} />
        <div className="search-section">
          <input
            type="text"
            placeholder="Search submissions..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <select 
            value={dateFilter} 
            onChange={(e) => setDateFilter(e.target.value)}
            className="date-filter"
          >
            <option value="all">All Time</option>
            <option value="today">Today</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
          </select>
        </div>

        <div className="submissions-list">
          <h2>Recent Submissions</h2>
          {isLoading ? (
            <div className="loading">Loading...</div>
          ) : error ? (
            <div className="error">{error}</div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={selectedSubmissions.length === submissions.length}
                    />
                  </th>
                  <th>Date</th>
                  <th>Files</th>
                  <th>IP Address</th>
                  <th>Location</th>
                  <th>Device Info</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {submissions.map((submission) => (
                  <tr key={submission.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedSubmissions.includes(submission.id)}
                        onChange={() => handleSelectSubmission(submission.id)}
                      />
                    </td>
                    <td>{new Date(submission.date).toLocaleString()}</td>
                    <td>
                      <div className="files-info">
                        {submission.files.map((file, index) => (
                          <div key={index} className="file-info">
                            <span>{file.originalName}</span>
                            <span className="file-size">
                              ({Math.round(file.size / 1024)}KB)
                            </span>
                          </div>
                        ))}
                      </div>
                    </td>
                    <td>{submission.ipAddress}</td>
                    <td className="location-info">
                      <div className="location-text">{formatLocation(submission.geoLocation)}</div>
                      {submission.geoLocation?.ll && (
                        <button 
                          className="map-button"
                          onClick={() => window.open(`https://www.google.com/maps?q=${submission.geoLocation.ll[0]},${submission.geoLocation.ll[1]}`)}
                          title="View on map"
                        >
                          🗺️
                        </button>
                      )}
                    </td>
                    <td className="device-info">{submission.userAgent}</td>
                    <td>
                      <div className="action-buttons">
                        {submission.files.map((file, index) => (
                          <button 
                            key={index}
                            onClick={() => window.open(`/uploads/${getFileName(file.path)}`)}
                            className="view-button"
                          >
                            View File {index + 1}
                          </button>
                        ))}
                        <button 
                          onClick={() => handleDelete(submission.id)} 
                          className="delete-button"
                          disabled={deletingId === submission.id}
                        >
                          {deletingId === submission.id ? 'Deleting...' : 'Delete'}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="dashboard-stats">
          <div className="stat-card">
            <h3>Total Submissions</h3>
            <p>{stats.totalSubmissions || 0}</p>
          </div>
          <div className="stat-card">
            <h3>Today's Submissions</h3>
            <p>{stats.todaySubmissions || 0}</p>
          </div>
          <div className="stat-card">
            <h3>Last Submission</h3>
            <p>{stats.lastSubmission ? new Date(stats.lastSubmission).toLocaleString() : 'N/A'}</p>
          </div>
          {Object.keys(stats.countryStats || {}).length > 0 && (
            <div className="stat-card">
              <h3>Top Country</h3>
              <p>{Object.entries(stats.countryStats)
                .sort(([,a], [,b]) => b - a)[0]?.[0] || 'N/A'}</p>
            </div>
          )}
        </div>

        <div className="bulk-actions">
          <button 
            onClick={handleBulkDownload}
            disabled={selectedSubmissions.length === 0}
          >
            Download Selected
          </button>
          <button 
            onClick={handleBulkArchive}
            disabled={selectedSubmissions.length === 0}
          >
            Archive Selected
          </button>
        </div>
      </div>
    </div>
  );
}

export default Admin; 
